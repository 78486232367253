import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="51" height="41" viewBox="0 0 51 41" fill="none" {...props}>
      <path
        d="M50.5 2.20576C50.2988 3.21106 50.1037 4.21804 49.8947 5.22164C48.9176 9.91864 47.9379 14.6151 46.9555 19.3109C45.8025 24.8341 44.6497 30.3568 43.4972 35.8788C43.3135 36.7599 43.1323 37.6419 42.9477 38.5238C42.9277 38.6193 42.9007 38.7148 42.8746 38.8094C42.2049 41.2525 40.3238 41.5271 38.4766 40.2506C35.4765 38.1775 32.5416 36.0165 29.5789 33.8927C28.8439 33.3656 28.0984 32.8494 27.3755 32.3096C27.1265 32.1238 26.9758 32.1516 26.7546 32.3612C25.0413 33.9826 23.3187 35.595 21.5868 37.1984C21.2019 37.5612 20.7829 37.8885 20.3354 38.1758C19.3774 38.7781 18.3036 38.5357 17.795 37.5473C17.4092 36.798 17.134 35.9903 16.8631 35.1912C15.6271 31.5468 14.4032 27.8984 13.1916 24.2462C13.0836 23.9209 12.9172 23.746 12.5688 23.6421C9.0801 22.6005 5.60182 21.5369 2.11221 20.4877C1.49302 20.3019 0.957416 20.0248 0.666543 19.4309C0.255488 18.5929 0.6204 17.5986 1.59491 17.0756C2.12267 16.7926 2.70962 16.6093 3.27744 16.3998C12.6655 12.9492 22.0547 9.50019 31.4451 6.05291C36.5671 4.17186 41.6905 2.29419 46.8153 0.419893C47.3378 0.228972 47.89 0.03974 48.4386 0.00510389C49.6091 -0.069237 50.3807 0.675017 50.4364 1.81379C50.4434 1.9405 50.4364 2.06722 50.4364 2.19394L50.5 2.20576ZM19.1632 35.4294L19.2267 35.421C19.5402 32.7531 19.8634 30.0861 20.1603 27.4175C20.2187 26.8937 20.3745 26.4933 20.8074 26.1174C27.5962 20.2213 34.3733 14.3132 41.1389 8.39296C41.246 8.29919 41.4045 8.21217 41.4376 8.09559C41.5056 7.85652 41.5047 7.59886 41.5325 7.34965C41.3026 7.33107 41.0693 7.28376 40.8437 7.30572C40.6965 7.32008 40.5537 7.42653 40.4187 7.50762C39.7649 7.90186 39.1129 8.29609 38.4627 8.69032L17.606 21.3857C16.7352 21.9154 15.8643 22.4476 14.9803 22.9908L19.1632 35.4294Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
