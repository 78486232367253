import React from 'react'
import { Layout } from 'antd'
import Menu from 'components/Menu'
import Footer from 'components/Footer'
import { Box } from 'widgets'
import AuthHeader from 'components/Menu/AuthHeader'
import { useRouter } from 'next/router'

const { Content } = Layout

const PublicLayout = ({ children, isAuth, isLogged }) => {
  const router = useRouter()

  if (isLogged || ['/', '/home', '/my-nft', '/ino', '/staking', '/history'].includes(router?.pathname) || isAuth) {
    return (
      <Layout
        style={{
          minHeight: '100vh',
          overflow: 'hidden',
          background: 'linear-gradient(180deg, #FFF 0%, #000314 0.01%, #00051F 100%)',
        }}
      >
        <div id="google_translate_element" />
        {isAuth ? <AuthHeader /> : <Menu />}
        <Content>
          <Box minHeight={isAuth ? '' : '100vh'} position="relative">
            {children}
          </Box>
        </Content>
        <Footer />
      </Layout>
    )
  }

  router.replace('/')
  return <></>
}

export default PublicLayout
