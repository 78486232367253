import React from 'react'
import { Svg, SvgProps } from 'widgets'

const ModalBorder: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="482" height="523" viewBox="0 0 482 523" fill="none" {...props}>
      <g filter="url(#filter0_b_22299_17058)">
        <path
          d="M0 24C0 10.7452 10.7452 0 24 0H421.525C428.138 0 434.458 2.72894 438.993 7.54252L475.469 46.2588C479.664 50.7117 482 56.5985 482 62.7163V498.5C482 511.755 471.255 522.5 458 522.5H24C10.7452 522.5 0 511.755 0 498.5L0 24Z"
          fill="url(#paint0_radial_22299_17058)"
        />
        <path
          d="M1 24C1 11.2974 11.2975 1 24 1H421.525C427.862 1 433.919 3.61524 438.265 8.22825L474.741 46.9445C478.761 51.2119 481 56.8534 481 62.7163V498.5C481 511.203 470.703 521.5 458 521.5H24C11.2975 521.5 1 511.203 1 498.5V24Z"
          stroke="url(#paint1_linear_22299_17058)"
          strokeOpacity="0.8"
          strokeWidth="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_22299_17058"
          x="-15"
          y="-15"
          width="512"
          height="552.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_22299_17058" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_22299_17058" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_22299_17058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(574.449 661.122) rotate(-129.443) scale(856.091 1385.57)"
        >
          <stop />
          <stop offset="0.485796" stopColor="#060606" />
          <stop offset="1" stopColor="#1B1B1B" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_22299_17058"
          x1="241"
          y1="1.69258e-07"
          x2="234.844"
          y2="679.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default ModalBorder
