import { Grid } from 'antd'
import React from 'react'

const { useBreakpoint } = Grid

const CloseModalButton = () => {
  const breakPoint = useBreakpoint()
  return (
    <div
      className="flex items-center justify-center h-full w-full [clip-path:polygon(100% 0, 0 0, 110% 100%)]"
      style={{
        clipPath: breakPoint?.sm ? 'polygon(100% 0, 0 0, 110% 100%)' : 'polygon(100% 0, 20% 0, 120% 100%)',
      }}
    >
      <img src="/images/icons/icon-close.png" alt="" />
    </div>
  )
}

export default CloseModalButton
