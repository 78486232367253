import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import Misc from 'utils/Misc'
import { getTransactionReceiptMined } from 'utils'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

const unlimited = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

const approve = async (_contract, _account, _spender, _amount, cbs, cbe, _withUnlimited) => {
  if (!_contract) return cbe('Contract is not defined!!!')
  try {
    return await _contract.approve(_spender, _withUnlimited ? unlimited : _amount).then(async (result: any) => {
      const recipt: any = await getTransactionReceiptMined(result.hash, 500)
      if (recipt.status === true) {
        cbs()
      } else {
        cbe('Occurred approve error!!!')
      }
    })
  } catch (error) {
    console.error('approve error', approve)
    return cbe('Occurred approve error!!!')
  }
}

const approveAllNFT = async (_contract, _account, _spender, cbs, cbe) => {
  if (!_contract) return cbe('Contract is not defined!!!')
  try {
    return await _contract.setApprovalForAll(_spender, true).then(async (result: any) => {
      const recipt: any = await getTransactionReceiptMined(result.hash, 500)
      if (recipt.status === true) {
        await Misc.sleep(200)
        cbs()
      } else {
        const messageError = 'Occurred approve error!!!'
        cbe(messageError)
      }
    })
  } catch (error) {
    console.error('approve error', approve)
    const messageError = 'Occurred approve error!!!'
    return cbe(messageError)
  }
}

const useApproveToken = (): [any, any, any] => {
  // check whether the user has approved the router on the input token
  const onApproveToken = useCallback(
    async (tokenContract, _account, _spender, _amount, _amountUse, cbs, cbe, _withUnlimited = true) => {
      const balanceAllowance = await tokenContract.allowance(_account, _spender)
      const parseBigBalance = new BigNumber(+balanceAllowance)
      if (parseBigBalance.isLessThan(+_amountUse)) {
        return approve(tokenContract, _account, _spender, _amount, cbs, cbe, _withUnlimited)
      }

      return cbs('Approved')
    },
    [],
  )

  const onApproveTokenNFT = useCallback(
    async (tokenContractNFT, _account, _spender, _amount, cbs, cbe, _withUnlimited = false) => {
      return approve(tokenContractNFT, _account, _spender, _amount, cbs, cbe, _withUnlimited)
    },
    [],
  )

  const onApproveAllNFT = useCallback(async (tokenContractNFT, _account, _spender, cbs, cbe) => {
    const resultFee = await tokenContractNFT.isApprovedForAll(_account, _spender)

    if (!resultFee) {
      return approveAllNFT(tokenContractNFT, _account, _spender, cbs, cbe)
    }
    return cbs('Approved')
  }, [])

  return [onApproveToken, onApproveTokenNFT, onApproveAllNFT]
}

export default useApproveToken
