import { createReducer } from '@reduxjs/toolkit'
import {
  ModalMyNftType,
  setModalBuyNow,
  setModalConnect,
  setModalLoading,
  setModalMyNft,
  setModalNftDetail,
  setModalNFTMarket,
  setModalStaking,
  setModalSuccess,
} from './actions'

export interface ModalState {
  modalConnect: any
  modalBuyNow: any
  modalMyNft: ModalMyNftType
  modalNftDetail: ModalMyNftType
  modalNftMarket: ModalMyNftType
  modalLoading: ModalMyNftType
  modalSuccess: ModalMyNftType
  modalStaking: ModalMyNftType
}

const initialState: ModalState = {
  modalConnect: { toggle: false, dataModal: null },
  modalBuyNow: { toggle: false, dataModal: null },
  modalMyNft: { toggle: false, dataModal: null },
  modalNftDetail: { toggle: false, dataModal: null },
  modalNftMarket: { toggle: false, dataModal: null },
  modalLoading: { toggle: false },
  modalSuccess: { toggle: false },
  modalStaking: { toggle: false, dataModal: null },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setModalConnect, (state, { payload }) => {
      state.modalConnect = payload
    })
    .addCase(setModalBuyNow, (state, { payload }) => {
      state.modalBuyNow = payload
    })
    .addCase(setModalMyNft, (state, { payload }) => {
      state.modalMyNft = payload
    })
    .addCase(setModalNftDetail, (state, { payload }) => {
      state.modalNftDetail = payload
    })
    .addCase(setModalNFTMarket, (state, { payload }) => {
      state.modalNftMarket = payload
    })
    .addCase(setModalLoading, (state, { payload }) => {
      state.modalLoading = payload
    })
    .addCase(setModalStaking, (state, { payload }) => {
      state.modalStaking = payload
    })
    .addCase(setModalSuccess, (state, { payload }) => {
      state.modalSuccess = payload
    }),
)
