import { useWeb3React } from '@web3-react/core'
import { Button, Input, InputNumber, Modal, Select } from 'antd'
import { NFT_CONTRACT, USDT_TOKEN } from 'config/constants/endpoints'
import { convertKeysToCamelCase } from 'helpers/CommonHelper'
import setListNFTs from 'hooks/setListNFTs'
import { useERC721, useMarketContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import debounce from 'lodash/debounce'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setModalConnect,
  setModalLoading,
  setModalMyNft,
  setModalNftDetail,
  setModalSuccess,
} from 'state/modal/actions'
import { setMyNft } from 'state/nft/actions'
import styled from 'styled-components'
import { getTransactionReceiptMined, isAddress, toLocaleString } from 'utils'
import { CloseIcon } from 'widgets'
import { useRouter } from 'next/router'
import useApproveToken from 'hooks/useApproveToken'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useGetFeeMarket from 'hooks/useGetFeeMarket'
import useCatchTxError from 'hooks/useCatchTxError'
import { useGetListNFTsMarKet } from 'hooks/graphQl/useGetListNFTsMarKet'
import { formatNumber } from 'utils/formatBalance'

import { Flex } from '../../../packages/uikit/src/components/Box'

const ModalContent = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 42px 0;
  }
`

const NftContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }

  p {
    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 16px;
    }
  }

  .item-left {
    background-image: url('/images/nfts/bg-nft.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 50%;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      width: 100%;
    }

    img {
      width: 54%;
      border-radius: 30px;
    }
  }

  .custom-text {
    font-family: 'Rammetto One', sans-serif;
    background: linear-gradient(to right, #00daf4, #5edc99, #bada6d, #ffc800, #ffaf00, #ff7800);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 42px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 64px;
    }
  }

  .set-price {
    .box-input-price {
      margin-bottom: 20px;

      > p {
        color: #d7d7d7;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 10px;
      }

      .input-price {
        position: relative;

        .ant-input-number {
          display: flex;
          align-items: center;
          padding-left: 20px;
          width: 100%;
          height: 73px;
          border: none;
          background-image: url('/images/marketplace/bg-input.png');
          background-size: 100% 100%;
          color: #fff;
          text-align: center;
          font-family: 'Rubik', sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;

          .ant-input-number-handler-wrap {
            display: none;
          }

          input {
            padding-right: 57%;
          }
        }

        .wrap-box-select {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 47%;

          .box-select {
            position: relative;
            width: 100%;

            img {
              position: absolute;
              top: 50%;
              right: 1px;
              transform: translateY(-50%);
              height: calc(100% - 2px);
              pointer-events: none;
              border-radius: 12px;
            }

            .ant-select-selector {
              display: flex;
              align-items: center;
              width: 100%;
              height: 62px;
              border-radius: 12px;
              border: 1px solid #fff;
              border-right: 2px solid #fff;
              background: rgba(255, 255, 255, 0.12);
              backdrop-filter: blur(61.5px);

              .ant-select-selection-item {
                color: #fff;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
`

const ConfirmButton = styled(Button)`
  height: auto;
  padding: 0;
  border: none !important;
  width: 100%;
  max-width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  background-image: url('/images/nfts/button-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  color: #000 !important;

  ${({ theme }) => theme.mediaQueries.sm} {
    span {
      font-size: 24px;
      line-height: 2;
    }
  }

  &:hover,
  &:focus,
  &:disabled {
    opacity: 1 !important;
    color: #000 !important;
    background-image: url('/images/nfts/button-bg.png') !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    border: none !important;
  }
`

const TransferButton = styled.button`
  background-color: transparent;
  height: auto;
  padding: 0;
  border: none;
  width: 100%;
  max-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  background-image: url('/images/nfts/button-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
    line-height: 2;
  }
`

const FormTransfer = styled.div`
  display: grid;
  grid-gap: 12px;

  input {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #fff;
    background: #000018;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 10px 24px;
      font-size: 20px;
    }
  }
`

const ModalNFTDetail = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { account } = useWeb3React()

  const { toggle, dataModal } = useSelector((state: any) => state.modal.modalNftDetail)
  const listMarketAll = useSelector((state: any) => state.nft.listMarketNft)

  // Hook
  const contractERC721 = useERC721(NFT_CONTRACT)
  const { toastError, toastSuccess } = useToast()
  const contractMarket = useMarketContract()
  const [feeMarket] = useGetFeeMarket()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { fetchWithCatchTxError } = useCatchTxError()
  const [getListMarket] = useGetListNFTsMarKet()

  const [, , onApproveAllNFT] = useApproveToken()

  // State
  const [isTransfer, setIsTransfer] = useState(false)
  const [isListMarket, setIsListMartket] = useState(false)
  const [isListed, setIsListed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transferAddress, setTransferAddress] = useState('')
  const [inputListMarket, setInputListMarket] = useState<number>(0)
  const [errorMassage, setErrorMessage] = useState('')
  const [selectTokenList, setSelectTokenList] = useState<string>('USDT')
  const [mySale, setMySale] = useState<any>()

  useEffect(() => {
    if (isListed) {
      setMySale(listMarketAll?.find((itemFind) => +itemFind?.nft?.id === +dataModal?.nft?.id))
    }
  }, [dataModal?.nft?.id, isListed, listMarketAll])

  useMemo(async () => {
    if (contractMarket && dataModal?.nft?.id) {
      const check = await contractMarket?.nftListedTime(dataModal?.nft?.id)

      setIsListed(+check > 0)
    }
  }, [contractMarket, dataModal?.nft?.id])

  const handleCancel = useCallback(() => {
    setErrorMessage('')
    setTransferAddress('')
    setIsTransfer(false)
    setIsListMartket(false)
    setInputListMarket(0)
    dispatch(setModalNftDetail({ toggle: false, dataModal: null }))
  }, [dispatch])

  const handleButtonTransfer = () => {
    if (!account) {
      dispatch(setModalConnect({ toggle: true }))
      return
    }

    if (dataModal?.type === 'DETAIL') {
      setIsTransfer((prev) => !prev)
      return
    }

    dispatch(setModalNftDetail({ toggle: false, dataModal: null }))
    dispatch(setModalMyNft({ toggle: true }))
    router.push('/my-nft')
  }

  const handleChangeInput = debounce((val: string) => {
    setTransferAddress(val)
    setErrorMessage('')
  }, 500)

  const handleConfirmTransfer = useCallback(async () => {
    if (!account) {
      dispatch(setModalConnect({ toggle: true }))
      return
    }

    if (!loading && contractERC721 && account && dataModal?.nft) {
      if (!isAddress(transferAddress)) {
        setErrorMessage('Wrong Wallet Address')
        return
      }

      if (isAddress(transferAddress) && transferAddress?.toLocaleLowerCase() === account?.toLocaleLowerCase()) {
        setErrorMessage('Do not use the address you are currently logged in')
        return
      }

      try {
        await contractERC721
          .transferFrom(account, transferAddress, dataModal?.nft?.id)
          .then(async (res) => {
            const receipt: any = await getTransactionReceiptMined(res?.hash, 500)

            if (receipt?.status) {
              await setListNFTs({ limit: 100, owner: account }, (resp) => {
                dispatch(setMyNft(convertKeysToCamelCase(resp?.data)))
              })

              setLoading(false)
              handleCancel()
              toastSuccess('Transfer successfully')
            }
          })
          .catch((e) => {
            setLoading(false)
            handleCancel()
            toastError('Transfer failed')
          })
      } catch (error) {
        setLoading(false)
        handleCancel()
        toastError('Transfer failed')
      }
    }
  }, [
    account,
    contractERC721,
    dataModal?.nft,
    dispatch,
    handleCancel,
    loading,
    toastError,
    toastSuccess,
    transferAddress,
  ])

  const handleButtonListMarket = () => {
    if (!account) {
      dispatch(setModalConnect({ toggle: true }))
      return
    }

    if (dataModal?.type === 'DETAIL') {
      setIsListMartket((prev) => !prev)
    }
  }

  const handleButtonSubmitList = useCallback(() => {
    if (!account) {
      dispatch(setModalConnect({ toggle: true }))
      return
    }

    if (!loading && contractERC721 && account && dataModal?.nft && contractMarket && inputListMarket > 0) {
      const params = {
        tokenContractNFT: contractERC721,
        _account: account,
        _spender: contractMarket?.address,
      }

      dispatch(
        setModalLoading({
          toggle: true,
        }),
      )

      onApproveAllNFT(
        params.tokenContractNFT,
        params._account,
        params._spender,
        async () => {
          const paramsList = {
            _prices: [toLocaleString(inputListMarket * 10 ** 18)],
            _quantities: [1],
            _tokenIds: [dataModal?.nft?.id],
            _acceptToken: selectTokenList === 'USDT' ? USDT_TOKEN : '0x0000000000000000000000000000000000000000',
            _nft: contractERC721?.address,
            feeMarket,
          }

          try {
            const receipt = await fetchWithCatchTxError(() => {
              return callWithGasPrice(
                contractMarket,
                'placeOrder',
                [
                  paramsList._prices,
                  paramsList._quantities,
                  paramsList._tokenIds,
                  paramsList._acceptToken,
                  paramsList._nft,
                ],
                {
                  value: `${paramsList.feeMarket}`,
                },
              )
            })

            if (receipt?.status) {
              setLoading(false)
              handleCancel()
              toastSuccess('List NFT successfully')
              getListMarket()
              dispatch(setModalSuccess({ toggle: true }))
              dispatch(
                setModalLoading({
                  toggle: false,
                }),
              )
            } else {
              setLoading(false)
              handleCancel()
              console.error('List NFT failed 1')
              dispatch(
                setModalLoading({
                  toggle: false,
                }),
              )
            }
          } catch (error) {
            setLoading(false)
            handleCancel()
            toastError('List NFT failed')
            console.error('List NFT failed 2', error)
            dispatch(
              setModalLoading({
                toggle: false,
              }),
            )
          }
        },
        (error) => {
          setLoading(false)
          handleCancel()
          toastError('List NFT failed')
          console.error('List NFT failed 3', error)
          dispatch(
            setModalLoading({
              toggle: false,
            }),
          )
        },
      )
    }
  }, [
    account,
    callWithGasPrice,
    contractERC721,
    contractMarket,
    dataModal?.nft,
    dispatch,
    feeMarket,
    fetchWithCatchTxError,
    getListMarket,
    handleCancel,
    inputListMarket,
    loading,
    onApproveAllNFT,
    selectTokenList,
    toastError,
    toastSuccess,
  ])

  const handleButtonWithdraw = useCallback(async () => {
    try {
      const paramsList = {
        _tokenIds: [dataModal?.nft?.id],
        _nft: contractERC721?.address,
        feeMarket,
      }

      dispatch(
        setModalLoading({
          toggle: true,
        }),
      )

      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(contractMarket, 'cancelOrder', [paramsList._tokenIds, paramsList._nft], {
          value: `${paramsList.feeMarket}`,
        })
      })

      if (receipt?.status) {
        setLoading(false)
        handleCancel()
        toastSuccess('Withdraw NFT successfully!')
        getListMarket()
        dispatch(
          setModalSuccess({
            toggle: true,
            dataModal: {
              type: 'withdraw',
            },
          }),
        )
        dispatch(
          setModalLoading({
            toggle: false,
          }),
        )
      } else {
        setLoading(false)
        handleCancel()
        toastError('Withdraw NFT failed!')
        console.error('Withdraw NFT failed 1')
        dispatch(
          setModalLoading({
            toggle: false,
          }),
        )
      }
    } catch (error) {
      setLoading(false)
      handleCancel()
      toastError('Withdraw NFT failed!')
      console.error('Withdraw NFT failed 2', error)
      dispatch(
        setModalLoading({
          toggle: false,
        }),
      )
    }
  }, [
    callWithGasPrice,
    contractERC721?.address,
    contractMarket,
    dataModal?.nft?.id,
    dispatch,
    feeMarket,
    fetchWithCatchTxError,
    getListMarket,
    handleCancel,
    toastError,
    toastSuccess,
  ])

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={787}
      footer={null}
      closeIcon={
        <div className="flex items-center justify-center w-full h-full">
          <CloseIcon />
        </div>
      }
      onCancel={handleCancel}
    >
      <ModalContent>
        <NftContent>
          <div className="item-left">
            <img src={dataModal?.nft?.animationUrl || dataModal?.nft?.image} alt="" />
          </div>

          <div className="flex flex-col">
            <p className="custom-text mb-4 md:text-left text-center">{dataModal?.nft?.name}</p>

            <div className="flex flex-col gap-3 mb-8">
              <div className="flex items-center justify-between">
                <p className="text-[#D1D1D1] font-[500]">NFT ID</p>
                <p className="text-right font-bold">#{dataModal?.nft?.id}</p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-[#D1D1D1] font-[500]">Rarity</p>
                <p className="text-right font-bold">
                  {dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Rare')?.value ||
                    dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Rare')?.value}
                </p>
              </div>

              {!isListMarket ? (
                <>
                  <div className="flex items-center justify-between">
                    <p className="text-[#D1D1D1] font-[500]">Description</p>
                    <p className="text-right font-bold">
                      {dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Description')?.value ||
                        dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Description')?.value}
                    </p>
                  </div>

                  <div className="flex items-center justify-between">
                    <p className="text-[#D1D1D1] font-[500]">Borntime</p>
                    <p className="text-right font-bold">
                      {dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Birthday')?.valueDate
                        ? moment(
                            dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Birthday')?.valueDate,
                          ).format('HH:mm [UTC] MMMM D')
                        : dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Birthday')?.value_date
                        ? moment(
                            dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Birthday')?.value_date,
                          ).format('HH:mm [UTC] MMMM D')
                        : '--'}
                    </p>
                  </div>
                </>
              ) : (
                <div className="set-price">
                  <div className="box-input-price">
                    <p>Set Price</p>

                    <div className="input-price">
                      <InputNumber
                        min={0}
                        placeholder="999"
                        value={inputListMarket}
                        onChange={(e) => {
                          setInputListMarket(e)
                        }}
                      />

                      <div className="wrap-box-select">
                        <div className="box-select">
                          <Select
                            value={selectTokenList}
                            style={{ width: '100%' }}
                            options={[
                              { value: 'USDT', label: 'USDT' },
                              { value: 'BNB', label: 'BNB' },
                            ]}
                            onChange={(e) => {
                              setSelectTokenList(e)
                            }}
                          />

                          <img src="/images/icons/arrow-down-big.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Flex>
                    <TransferButton onClick={handleButtonListMarket}>Cancel</TransferButton>

                    <TransferButton onClick={handleButtonSubmitList}>Submit</TransferButton>
                  </Flex>
                </div>
              )}

              {isListed && (
                <div className="flex items-center justify-between">
                  <p className="text-[#D1D1D1] font-[500]">Current price</p>

                  {mySale?.tokenAddress !== '0x0000000000000000000000000000000000000000' ? (
                    <p className="text-right font-bold flex items-center" style={{ fontSize: '20px' }}>
                      <img width={35} className="mr-1" src="/images/icons/usdt-o.png" alt="" />{' '}
                      {formatNumber(+dataModal?.nft?.priceList)} USDT
                    </p>
                  ) : (
                    <p className="text-right font-bold flex items-center" style={{ fontSize: '20px' }}>
                      <img width={35} className="mr-1" src="/images/coins/bnb.png" alt="" />{' '}
                      {formatNumber(+dataModal?.nft?.priceList)} BNB
                    </p>
                  )}
                </div>
              )}
            </div>

            {!isTransfer && !isListMarket && (
              <Flex>
                {!isListed && (
                  <TransferButton onClick={handleButtonTransfer}>
                    {dataModal?.type === 'DETAIL' ? 'Transfer' : 'Go to my NFT'}
                  </TransferButton>
                )}

                {isListed ? (
                  <TransferButton onClick={handleButtonWithdraw}>Withdraw</TransferButton>
                ) : (
                  <TransferButton onClick={handleButtonListMarket}>List NFT</TransferButton>
                )}
              </Flex>
            )}
          </div>
        </NftContent>

        {dataModal?.type === 'DETAIL' && isTransfer && (
          <FormTransfer className="mt-[15px]">
            <p className="md:text-[20px] text-[18px] leading-[1] text-[#E4E4E4] lg:pl-8 md:text-left text-center">
              Send to wallet address
            </p>
            <Input placeholder="Wallet Address" onChange={(e) => handleChangeInput(e?.target?.value)} />
            {errorMassage && <p className="text-[12px] text-[#ff4d4e] text-center">{errorMassage}</p>}
            <ConfirmButton onClick={handleConfirmTransfer} disabled={loading}>
              Confirm
            </ConfirmButton>
          </FormTransfer>
        )}
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalNFTDetail)
