import { createReducer } from '@reduxjs/toolkit'
import { BalanceType, WalletType } from 'config/types/Wallet'
import { setOpvWallet, setUsdtWallet, setWalletBalance } from './actions'

export interface WalletState {
  opvWallet: WalletType | undefined
  usdtWallet: WalletType | undefined
  balances: any
}

export const initialState: WalletState = {
  opvWallet: undefined,
  usdtWallet: undefined,
  balances: {},
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setOpvWallet, (state, action) => {
      state.opvWallet = action.payload
    })
    .addCase(setUsdtWallet, (state, action) => {
      state.usdtWallet = action.payload
    })
    .addCase(setWalletBalance, (state, action) => {
      state.balances = action.payload
    }),
)
