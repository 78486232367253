import { MARKET_CONTRACT_ADDRESS } from 'config/constants/endpoints'
import { useEffect, useState, useCallback } from 'react'
import Web3Contract from 'utils/Web3Token'
import ABI_MAERKET from '../config/abi/abi_market.json'

const { contract: contractMethod } = new Web3Contract(MARKET_CONTRACT_ADDRESS, ABI_MAERKET)

const useGetFeeMarket = () => {
  const [data, setData] = useState<any>(undefined)

  const getData = useCallback(async () => {
    if (contractMethod) {
      try {
        const FEE = await contractMethod.methods.feeMarket().call()

        setData(FEE)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useGetFeeMarket
