import { API_META_NFT, NFT_CONTRACT } from 'config/constants/endpoints'
import fetchHelper from 'helpers/FetchHelper'
import * as qs from 'query-string'

function setListNFTs(payload: any, cbs?: any, cbe?: any) {
  const qStr = qs.stringify(payload)

  try {
    fetchHelper
      .fetch(`${API_META_NFT}/nft/${NFT_CONTRACT}/list?${qStr}`, {
        method: 'GET',
      })
      .then(([resp, status]: any) => {
        if (status === 200) {
          if (cbs) cbs(resp.data)
        } else if (cbe) cbe(resp)
      })
  } catch (error) {
    console.error('setListNFTs', error)
  }

  return null
}

export default setListNFTs
