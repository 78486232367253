/* eslint-disable no-await-in-loop */
/* eslint-disable no-constant-condition */
import { useCallback, useEffect } from 'react'
import { gql } from 'graphql-request'
import { useDispatch } from 'react-redux'
import { setFillOrders } from 'state/nft/actions'
import fetchHelper from 'helpers/FetchHelper'
import { API_META_NFT, NFT_CONTRACT } from 'config/constants/endpoints'

import { infoClient } from './index'

const QUERY = gql`
  query MyQuery($first: Int, $skip: Int) {
    fillOrders(first: $first, skip: $skip) {
      blockNumber
      blockTimestamp
      buyer
      id
      price
      quantity
      seller
      tokenId
      transactionHash
      tokenAddress
    }
  }
`

export const getFillOrders = async (): Promise<{ data?: any; error: boolean }> => {
  try {
    const first = 1000
    let skip = 0
    let data: any | undefined

    while (true) {
      const dataRs = await infoClient.request(QUERY, { first: Number(first), skip: Number(skip) })

      if (!data) {
        data = {
          fillOrders: [],
        }
      }

      if (!dataRs || !dataRs.fillOrders || dataRs.fillOrders.length === 0) {
        break
      }

      data.fillOrders.push(...dataRs.fillOrders)

      skip += 1000
    }

    return {
      data,
      error: false,
    }
  } catch (error) {
    console.error('getFillOrders', error)
    return { error: true }
  }
}

export const getNFTInfo = async (nftId) => {
  try {
    const result = await fetchHelper.fetch(`${API_META_NFT}/nft/${NFT_CONTRACT}/${nftId}`, {
      method: 'GET',
    })

    return {
      result,
      error: false,
    }
  } catch (error) {
    console.error('getListRoundsWin', error)
    return { error: true }
  }
}

export const useGetFillOrders = () => {
  const dispatch = useDispatch()

  const fetchGetFillOrders = useCallback(async () => {
    const { data } = await getFillOrders()

    if (data?.fillOrders) {
      const promises = data?.fillOrders.map(async (itemMap) => {
        const { result } = await getNFTInfo(itemMap?.tokenId)
        return {
          ...itemMap,
          nft: result?.[0],
        }
      })
      const dataMergeNFTInfo = await Promise.all(promises)

      dispatch(setFillOrders(dataMergeNFTInfo))
    }
  }, [dispatch])

  useEffect(() => {
    fetchGetFillOrders()
  }, [fetchGetFillOrders])

  return [fetchGetFillOrders]
}
