import { useWeb3React } from '@web3-react/core'
import { InputNumber, Modal } from 'antd'
import { NFT_CONTRACT, USDT_TOKEN } from 'config/constants/endpoints'
import { useERC721, useMarketContract, useTokenContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalConnect, setModalLoading, setModalNFTMarket } from 'state/modal/actions'
import styled from 'styled-components'
import { CloseIcon } from 'widgets'
import useApproveToken from 'hooks/useApproveToken'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useGetFeeMarket from 'hooks/useGetFeeMarket'
import useCatchTxError from 'hooks/useCatchTxError'
import { useGetListNFTsMarKet } from 'hooks/graphQl/useGetListNFTsMarKet'
import { useGetFillOrders } from 'hooks/graphQl/useGetFillOrders'
import { formatNumber } from 'utils/formatBalance'
import { toLocaleString } from 'utils'

const ModalContent = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 42px 0;
  }
`

const NftContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }

  p {
    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 16px;
    }
  }

  .item-left {
    background-image: url('/images/nfts/bg-nft.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 50%;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      width: 100%;
    }

    img {
      width: 54%;
      border-radius: 30px;
    }
  }

  .custom-text {
    font-family: 'Rammetto One', sans-serif;
    background: linear-gradient(to right, #00daf4, #5edc99, #bada6d, #ffc800, #ffaf00, #ff7800);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 42px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 64px;
    }
  }

  .set-price {
    .box-input-price {
      margin-bottom: 20px;

      .input-price {
        position: relative;

        .input-currency {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          z-index: 1;
        }

        .ant-input-number {
          display: flex;
          align-items: center;
          padding-left: 40px;
          width: 100%;
          height: 73px;
          border: none;
          background-image: url('/images/marketplace/bg-input.png');
          background-size: 100% 100%;
          color: #fff;
          text-align: center;
          font-family: 'Rubik', sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;

          .ant-input-number-handler-wrap {
            display: none;
          }

          input {
            padding-right: 57%;
          }
        }

        .wrap-box-select {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 47%;

          .box-select {
            position: relative;
            width: 100%;

            img {
              position: absolute;
              top: 50%;
              right: 1px;
              transform: translateY(-50%);
              height: calc(100% - 2px);
              pointer-events: none;
              border-radius: 12px;
            }

            .ant-select-selector {
              display: flex;
              align-items: center;
              width: 100%;
              height: 62px;
              border-radius: 12px;
              border: 1px solid #fff;
              border-right: 2px solid #fff;
              background: rgba(255, 255, 255, 0.12);
              backdrop-filter: blur(61.5px);

              .ant-select-selection-item {
                color: #fff;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
`

const TransferButton = styled.div`
  background-color: transparent;
  height: auto;
  padding: 0;
  border: none;
  width: 100%;
  max-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  background-image: url('/images/nfts/button-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
    line-height: 2;
  }
`

const ModalNFTMarket = () => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const { toggle, dataModal } = useSelector((state: any) => state.modal.modalNftMarket)

  // Hook
  const contractERC721 = useERC721(NFT_CONTRACT)
  const tokenContract = useTokenContract(USDT_TOKEN)
  const { toastError, toastSuccess } = useToast()
  const contractMarket = useMarketContract()
  const [feeMarket] = useGetFeeMarket()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { fetchWithCatchTxError } = useCatchTxError()
  const [getListMarket] = useGetListNFTsMarKet()
  const [getFillOrders] = useGetFillOrders()

  const [onApproveToken] = useApproveToken()

  // State
  const [loading, setLoading] = useState(false)

  const handleCancel = useCallback(() => {
    dispatch(setModalNFTMarket({ toggle: false, dataModal: null }))
  }, [dispatch])

  const handleButtonSubmitBuy = useCallback(async () => {
    if (!account) {
      dispatch(setModalConnect({ toggle: true }))
      return
    }

    if (!loading && contractERC721 && account && dataModal?.nft && contractMarket && tokenContract) {
      const balanceUSDT = await tokenContract?.balanceOf(account)

      if (+balanceUSDT < +dataModal?.nft?.dataList?.price) {
        toastError(`you don't have enough USDT to buy`)
        return
      }

      dispatch(
        setModalLoading({
          toggle: true,
        }),
      )

      const params = {
        tokenContract,
        _account: account,
        _spender: contractMarket?.address,
        _amount: balanceUSDT?.toString(),
        _amountUse: dataModal?.nft?.dataList?.price,
      }

      onApproveToken(
        params.tokenContract,
        params._account,
        params._spender,
        params._amount,
        params._amountUse,
        async () => {
          const paramsList = {
            _tokenIds: [dataModal?.nft?.id],
            _nft: contractERC721?.address,
            feeMarket,
          }

          try {
            const receipt = await fetchWithCatchTxError(() => {
              return callWithGasPrice(
                contractMarket,
                'fillOrderWithSignOnChain',
                [paramsList._tokenIds, paramsList._nft],
                {
                  value: `${
                    dataModal?.nft?.dataList?.tokenAddress !== '0x0000000000000000000000000000000000000000'
                      ? paramsList.feeMarket
                      : toLocaleString(+paramsList.feeMarket + +params._amountUse)
                  }`,
                },
              )
            })

            if (receipt?.status) {
              setLoading(false)
              handleCancel()
              toastSuccess('Buy NFT successfully')
              dispatch(
                setModalLoading({
                  toggle: false,
                }),
              )
              getListMarket()
              getFillOrders()
            } else {
              setLoading(false)
              handleCancel()
              console.error('Buy NFT failed 1')
              dispatch(
                setModalLoading({
                  toggle: false,
                }),
              )
            }
          } catch (error) {
            setLoading(false)
            handleCancel()
            toastError('Buy NFT failed')
            console.error('Buy NFT failed 2', error)
            dispatch(
              setModalLoading({
                toggle: false,
              }),
            )
          }
        },
        (error) => {
          setLoading(false)
          handleCancel()
          toastError('Buy NFT failed')
          console.error('Buy NFT failed 3', error)
          dispatch(
            setModalLoading({
              toggle: false,
            }),
          )
        },
      )
    }
  }, [
    account,
    callWithGasPrice,
    contractERC721,
    contractMarket,
    dataModal?.nft,
    dispatch,
    feeMarket,
    fetchWithCatchTxError,
    getFillOrders,
    getListMarket,
    handleCancel,
    loading,
    onApproveToken,
    toastError,
    toastSuccess,
    tokenContract,
  ])

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={787}
      footer={null}
      closeIcon={
        <div className="flex items-center justify-center w-full h-full">
          <CloseIcon />
        </div>
      }
      onCancel={handleCancel}
    >
      <ModalContent>
        <NftContent>
          <div className="item-left">
            <img src={dataModal?.nft?.animation_url || dataModal?.nft?.image} alt="" />
          </div>

          <div className="flex flex-col">
            <p className="custom-text mb-4 md:text-left text-center">{dataModal?.nft?.name}</p>

            <div className="flex flex-col gap-3 mb-8">
              <div className="flex items-center justify-between">
                <p className="text-[#D1D1D1] font-[500]">NFT ID</p>
                <p className="text-right font-bold">#{dataModal?.nft?.id}</p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-[#D1D1D1] font-[500]">Rarity</p>
                <p className="text-right font-bold">
                  {dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Rare')?.value}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-[#D1D1D1] font-[500]">Description</p>
                <p className="text-right font-bold">
                  {dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Description')?.value}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-[#D1D1D1] font-[500]">Borntime</p>
                <p className="text-right font-bold">
                  {dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Birthday')?.value_date
                    ? moment(dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Birthday')?.value_date).format(
                        'HH:mm [UTC] MMMM D',
                      )
                    : '--'}
                </p>
              </div>

              <div className="set-price">
                <div className="box-input-price">
                  <div className="input-price">
                    {dataModal?.nft?.dataList?.tokenAddress !== '0x0000000000000000000000000000000000000000' ? (
                      <img width={35} className="input-currency" src="/images/icons/usdt-o.png" alt="" />
                    ) : (
                      <img width={35} className="input-currency" src="/images/coins/bnb.png" alt="" />
                    )}

                    <InputNumber value={formatNumber(+(+dataModal?.nft?.dataList?.price / 10 ** 18))} disabled />

                    <div className="wrap-box-select">
                      <TransferButton onClick={handleButtonSubmitBuy}>Buy</TransferButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NftContent>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalNFTMarket)
