/* eslint-disable no-await-in-loop */
/* eslint-disable no-constant-condition */
import { useCallback, useEffect } from 'react'
import { gql } from 'graphql-request'
import { useDispatch } from 'react-redux'
import { setHistoriesMarket, setListMarketNft } from 'state/nft/actions'
import fetchHelper from 'helpers/FetchHelper'
import { API_META_NFT, NFT_CONTRACT } from 'config/constants/endpoints'

import { infoClient } from './index'

const QUERY = gql`
  query MyQuery($first: Int, $skip: Int) {
    placeOrders(first: $first, skip: $skip) {
      id
      blockNumber
      blockTimestamp
      price
      quantity
      seller
      status
      tokenId
      transactionHash
      tokenAddress
    }
  }
`

export const getListNFTsMarKet = async (): Promise<{ data?: any; error: boolean }> => {
  try {
    const first = 1000
    let skip = 0
    let data: any | undefined

    while (true) {
      const dataRs = await infoClient.request(QUERY, { first: Number(first), skip: Number(skip) })

      if (!data) {
        data = {
          placeOrders: [],
        }
      }

      if (!dataRs || !dataRs.placeOrders || dataRs.placeOrders.length === 0) {
        break
      }

      data.placeOrders.push(...dataRs.placeOrders)

      skip += 1000
    }

    return {
      data,
      error: false,
    }
  } catch (error) {
    console.error('getListNFTsMarKet', error)
    return { error: true }
  }
}

export const getNFTInfo = async (nftId) => {
  try {
    const result = await fetchHelper.fetch(`${API_META_NFT}/nft/${NFT_CONTRACT}/${nftId}`, {
      method: 'GET',
    })

    return {
      result,
      error: false,
    }
  } catch (error) {
    console.error('getListRoundsWin', error)
    return { error: true }
  }
}

export const useGetListNFTsMarKet = () => {
  const dispatch = useDispatch()

  const fetchGetDeposits = useCallback(async () => {
    const { data } = await getListNFTsMarKet()

    if (data?.placeOrders) {
      const promises = data?.placeOrders.map(async (itemMap) => {
        const { result } = await getNFTInfo(itemMap?.tokenId)
        return {
          ...itemMap,
          nft: result?.[0],
        }
      })
      const dataMergeNFTInfo = await Promise.all(promises)

      dispatch(setHistoriesMarket(dataMergeNFTInfo))
      dispatch(setListMarketNft(dataMergeNFTInfo?.filter((itemFilter) => itemFilter?.status === false)))
    }
  }, [dispatch])

  useEffect(() => {
    fetchGetDeposits()
  }, [fetchGetDeposits])

  return [fetchGetDeposits]
}
