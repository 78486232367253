import { ConnectorNames } from 'connectors'
import { ROOT_DOMAIN } from 'config/constants'
import Metamask from './icons/Metamask'
import MathWallet from './icons/MathWallet'
import TokenPocket from './icons/TokenPocket'
import TrustWallet from './icons/TrustWallet'
import WalletConnect from './icons/WalletConnect'
import BinanceChain from './icons/BinanceChain'
import SafePalWallet from './icons/SafePalWallet'
import { Config } from './types'

const connectors: Config[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    href: `https://metamask.app.link/dapp/${ROOT_DOMAIN}`,
  },
  {
    title: 'Wallet Connect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Trust Wallet',
    icon: TrustWallet,
    connectorId: ConnectorNames.Injected,
    href: `https://link.trustwallet.com/open_url?url=${ROOT_DOMAIN}`,
  },
  // {
  //   title: 'MathWallet',
  //   icon: MathWallet,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'TokenPocket',
  //   icon: TokenPocket,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'Binance Wallet',
  //   icon: BinanceChain,
  //   connectorId: ConnectorNames.BSC,
  // },
  {
    title: 'SafePal',
    icon: SafePalWallet,
    connectorId: ConnectorNames.Injected,
    href: `https://www.safepal.com/en/download`,
  },
]

export default connectors
