import React from 'react'
import styled from 'styled-components'
import { Link } from 'widgets'
import { Login, Config } from './types'
import { connectorLocalStorageKey } from '../../../packages/uikit/src/widgets/WalletModal'

const WalletStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 12px;
  cursor: pointer;

  width: 100%;
  max-width: 130px;
  height: auto;
  aspect-ratio: 1/1;
  margin: 0 auto;

  border-radius: 12px;
  transition: all 0.3s ease;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 180px;
    padding: 24px;
    row-gap: 15px;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 100%;
    border-radius: 12px;
    opacity: 0.24;
  }

  &#wallet-connect-metamask::before {
    background: radial-gradient(141.42% 141.42% at 100% 100%, #ff7a00 0%, rgba(0, 0, 0, 0) 50%);
  }

  &#wallet-connect-wallet::before {
    background: radial-gradient(141.42% 141.42% at 100% 100%, #00d1ff 0%, rgba(0, 0, 0, 0) 50%);
  }

  &#wallet-connect-trust::before {
    background: radial-gradient(143.92% 143.92% at 97.5% 100%, #000aff 0%, rgba(0, 0, 0, 0) 50%);
  }

  &#wallet-connect-safepal::before {
    background: radial-gradient(141.42% 141.42% at 100% 100%, #001aff 0%, rgba(0, 0, 0, 0) 50%);
  }

  :hover {
    transform: scale(1.03);

    ::before {
      opacity: 0.5;
    }
  }

  .modal-title {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 16px;
    }
  }

  svg {
    width: 32px;
    height: 32px;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 42px;
      height: 42px;
    }

    &.custom-icon {
      width: 46px;
      height: 46px;
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.12;

      ${({ theme }) => theme.mediaQueries.sm} {
        width: 80px;
        height: 80px;
      }
    }
  }
`

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig

  let linkAction: any = {
    onClick: () => {
      login(walletConfig.connectorId)
      window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
      onDismiss()
    },
  }

  if (typeof window !== 'undefined' && !window.ethereum && walletConfig.href) {
    linkAction = {
      style: {
        textDecoration: 'none',
      },
      as: Link,
      href: walletConfig.href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return (
    <WalletStyled id={`wallet-connect-${title.split(' ')[0].toLocaleLowerCase()}`} {...linkAction}>
      <Icon />
      <div className="modal-title">{title}</div>
      <Icon className="custom-icon" />
    </WalletStyled>
  )
}

export default WalletCard
