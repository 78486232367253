/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalSuccess } from 'state/modal/actions'
import styled from 'styled-components'
import { CloseIcon } from 'widgets'
import { useRouter } from 'next/router'

const ModalContent = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 42px 0;
  }

  text-align: center;

  .modal-ss-title {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
    }

    p {
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      background: linear-gradient(to right, #00daf4, #5edc99, #bada6d, #ffc800, #ffaf00, #ff7800);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
  }

  > p {
    max-width: 500px;
    margin: auto;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  > img {
    cursor: pointer;
  }
`

const ModalNFTDetail = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const { toggle, dataModal } = useSelector((state: any) => state.modal.modalSuccess)

  const handleCancel = useCallback(() => {
    dispatch(setModalSuccess({ toggle: false, dataModal: null }))
  }, [dispatch])

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={787}
      footer={null}
      closeIcon={
        <div className="flex items-center justify-center w-full h-full">
          <CloseIcon />
        </div>
      }
      onCancel={handleCancel}
    >
      <ModalContent>
        <div className="modal-ss-title">
          <img src="/images/marketplace/icon-ss.png" alt="" />
          <p>{dataModal?.type === 'withdraw' ? 'Successful withdrawal' : 'Listing Completed'}</p>
        </div>

        <p>Congratulations, you have successfully withdrawn NFT!</p>

        <img
          style={{ margin: 'auto' }}
          onClick={() => {
            handleCancel()
            router.push('/my-nft')
          }}
          src="/images/marketplace/btn-go-to-my-nft.png"
          alt=""
        />
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalNFTDetail)
