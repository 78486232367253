import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16 10.333C13.48 10.333 13 11.3863 13 13.333V14.1597H19V13.333C19 11.3863 18.52 10.333 16 10.333Z"
        fill="white"
      />
      <path
        d="M16 10.333C13.48 10.333 13 11.3863 13 13.333V14.1597H19V13.333C19 11.3863 18.52 10.333 16 10.333Z"
        fill="url(#paint0_linear_19482_11991)"
      />
      <path
        d="M15.9999 20.1335C16.8099 20.1335 17.4665 19.4769 17.4665 18.6669C17.4665 17.8568 16.8099 17.2002 15.9999 17.2002C15.1899 17.2002 14.5332 17.8568 14.5332 18.6669C14.5332 19.4769 15.1899 20.1335 15.9999 20.1335Z"
        fill="white"
      />
      <path
        d="M15.9999 20.1335C16.8099 20.1335 17.4665 19.4769 17.4665 18.6669C17.4665 17.8568 16.8099 17.2002 15.9999 17.2002C15.1899 17.2002 14.5332 17.8568 14.5332 18.6669C14.5332 19.4769 15.1899 20.1335 15.9999 20.1335Z"
        fill="url(#paint1_linear_19482_11991)"
      />
      <path
        d="M15.9998 2.66699C8.63984 2.66699 2.6665 8.64033 2.6665 16.0003C2.6665 23.3603 8.63984 29.3337 15.9998 29.3337C23.3598 29.3337 29.3332 23.3603 29.3332 16.0003C29.3332 8.64033 23.3598 2.66699 15.9998 2.66699ZM23.1732 19.3337C23.1732 22.267 22.2665 23.1737 19.3332 23.1737H12.6665C9.73317 23.1737 8.8265 22.267 8.8265 19.3337V18.0003C8.8265 15.7203 9.37317 14.667 10.9998 14.307V13.3337C10.9998 12.0937 10.9998 8.33366 15.9998 8.33366C20.9998 8.33366 20.9998 12.0937 20.9998 13.3337V14.307C22.6265 14.667 23.1732 15.7203 23.1732 18.0003V19.3337Z"
        fill="white"
      />
      <path
        d="M15.9998 2.66699C8.63984 2.66699 2.6665 8.64033 2.6665 16.0003C2.6665 23.3603 8.63984 29.3337 15.9998 29.3337C23.3598 29.3337 29.3332 23.3603 29.3332 16.0003C29.3332 8.64033 23.3598 2.66699 15.9998 2.66699ZM23.1732 19.3337C23.1732 22.267 22.2665 23.1737 19.3332 23.1737H12.6665C9.73317 23.1737 8.8265 22.267 8.8265 19.3337V18.0003C8.8265 15.7203 9.37317 14.667 10.9998 14.307V13.3337C10.9998 12.0937 10.9998 8.33366 15.9998 8.33366C20.9998 8.33366 20.9998 12.0937 20.9998 13.3337V14.307C22.6265 14.667 23.1732 15.7203 23.1732 18.0003V19.3337Z"
        fill="url(#paint2_linear_19482_11991)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19482_11991"
          x1="13"
          y1="11.7149"
          x2="19"
          y2="11.7149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00DAF4" />
          <stop offset="0.2" stopColor="#5EDC99" />
          <stop offset="0.4" stopColor="#BADA6D" />
          <stop offset="0.6" stopColor="#FFC800" />
          <stop offset="0.8" stopColor="#FFAF00" />
          <stop offset="1" stopColor="#FF7800" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_19482_11991"
          x1="14.5332"
          y1="18.2595"
          x2="17.4665"
          y2="18.2595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00DAF4" />
          <stop offset="0.2" stopColor="#5EDC99" />
          <stop offset="0.4" stopColor="#BADA6D" />
          <stop offset="0.6" stopColor="#FFC800" />
          <stop offset="0.8" stopColor="#FFAF00" />
          <stop offset="1" stopColor="#FF7800" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_19482_11991"
          x1="2.6665"
          y1="12.2966"
          x2="29.3332"
          y2="12.2966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00DAF4" />
          <stop offset="0.2" stopColor="#5EDC99" />
          <stop offset="0.4" stopColor="#BADA6D" />
          <stop offset="0.6" stopColor="#FFC800" />
          <stop offset="0.8" stopColor="#FFAF00" />
          <stop offset="1" stopColor="#FF7800" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
