import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { NftDataType } from './types'

export interface ModalState {
  myNftList: NftDataType[] | null
  listMarketNft: any[]
  historiesMarket: any[]
  fillOrders: any[]
}

const initialState: ModalState = {
  myNftList: null,
  listMarketNft: [],
  historiesMarket: [],
  fillOrders: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setMyNft, (state, { payload }) => {
      state.myNftList = payload
    })
    .addCase(actions.setListMarketNft, (state, { payload }) => {
      state.listMarketNft = payload
    })
    .addCase(actions.setHistoriesMarket, (state, { payload }) => {
      state.historiesMarket = payload
    })
    .addCase(actions.setFillOrders, (state, { payload }) => {
      state.fillOrders = payload
    }),
)
