import { createAction } from '@reduxjs/toolkit'

export type ModalMyNftType = {
  toggle: boolean
  dataModal?: any
}

export const setModalConnect = createAction<any>('modal/setModalConnect')
export const setModalBuyNow = createAction<any>('modal/setModalBuyNow')
export const setModalMyNft = createAction<ModalMyNftType>('modal/setModalMyNft')
export const setModalNftDetail = createAction<ModalMyNftType>('modal/setModalNftDetail')
export const setModalNFTMarket = createAction<ModalMyNftType>('modal/setModalNFTMarket')
export const setModalLoading = createAction<ModalMyNftType>('modal/setModalLoading')
export const setModalSuccess = createAction<ModalMyNftType>('modal/setModalSuccess')
export const setModalStaking = createAction<ModalMyNftType>('modal/setModalStaking')
