/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core'
import { Col, Modal, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import CloseModalButton from 'components/CloseModalButton'
import InputCopy from 'components/CoppyItem/InputCopy'
import useAuth from 'hooks/useAuth'
import { setModalConnect } from 'state/modal/actions'
import BaseButton from 'widgets/BaseButton'
import { AppState } from '../../state/index'
import ModalBorder from './ModalBorder'
import WalletCard from './WalletCard'
import config from './config'

const ModalConnectStyle = styled.div``

const ModalContent = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  bottom: 12px;
  right: 12px;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    top: 24px;
    left: 24px;
    bottom: 24px;
    right: 24px;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #f1f1f1;
    margin-bottom: 24px;

    a {
      color: #3772ff;
    }
  }
`

const WrapperAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .btn-logout {
    width: fit-content;
    margin: 0 auto;
  }
`

const ModalConnect = () => {
  const dispatch = useDispatch()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const { toggle } = useSelector((state: AppState) => state.modal.modalConnect)

  const handleCancel = () => {
    dispatch(setModalConnect({ toggle: false }))
  }
  const handleLogout = () => {
    logout()
    handleCancel()
  }

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={480}
      className="modal-connect"
      footer={null}
      closeIcon={<CloseModalButton />}
      onCancel={handleCancel}
    >
      <ModalConnectStyle>
        <ModalBorder className="modal-border" />
        {account ? (
          <ModalContent>
            <WrapperAccount>
              <p className="sm:text-[24px] text-[20px] leading-1 text-white">Your wallet</p>

              <InputCopy title="Your address" value={account} />

              <BaseButton className="btn-logout" onClick={handleLogout}>
                Logout
              </BaseButton>
            </WrapperAccount>
          </ModalContent>
        ) : (
          <ModalContent>
            <p className="sm:text-[24px] text-[20px] leading-1 text-white">Connect a wallet</p>
            <Row gutter={[16, 16]}>
              {config.map((entry) => (
                <Col key={entry.title} span={12}>
                  <WalletCard key={entry.title} walletConfig={entry} login={login} onDismiss={handleCancel} />
                </Col>
              ))}
            </Row>
          </ModalContent>
        )}
      </ModalConnectStyle>
    </Modal>
  )
}

export default React.memo(ModalConnect)
