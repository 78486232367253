import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M10 3.75H7.5C6.83696 3.75 6.20107 4.01339 5.73223 4.48223C5.26339 4.95107 5 5.58696 5 6.25V23.75C5 24.413 5.26339 25.0489 5.73223 25.5178C6.20107 25.9866 6.83696 26.25 7.5 26.25H22.5C23.163 26.25 23.7989 25.9866 24.2678 25.5178C24.7366 25.0489 25 24.413 25 23.75V6.25C25 5.58696 24.7366 4.95107 24.2678 4.48223C23.7989 4.01339 23.163 3.75 22.5 3.75H17.5M10 3.75V15L13.75 11.25L17.5 15V3.75M10 3.75H17.5"
        stroke="#03F1E3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
