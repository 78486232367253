import { Box } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { Dropdown } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import { deleteAuthToken } from 'helpers/FetchHelper'
import { setGetNameSevice } from 'hooks/setGetNameService'
import { useOpxContract } from 'hooks/useContract'
import useGetDataByAddress from 'hooks/useGetDataByAddress'
import useIsMobile from 'hooks/useIsMobile'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import { updateUserInfo } from 'state/user/actions'
import { useUserData } from 'state/user/hooks/useUserData'
import { useGetUsdtWallet } from 'state/wallet/useGetWalletData'
import styled from 'styled-components'
import { Link } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import CollapseIcon from './CollapseIcon'
import DropdownUserMenu from './DropdownUserMenu'
import MobileSlider from './MobileSlider'
import { MenuEntry } from './types'

const WrapperHeader = styled.header<{ background: string }>`
  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  transition: all ease-in 0.25s;
  z-index: 15;
  background: ${({ background }) => background};
  width: 100%;

  .content {
    display: flex;
    justify-content: space-between;

    padding: 16px;
    margin: 0 auto;
    width: 100%;
    max-width: 1332px;

    .menu-left {
      display: flex;
      justify-content: space-between;

      img {
        width: 100%;
        max-width: 56px;
        height: auto;
        aspect-ratio: 1/1;

        ${({ theme }) => theme.mediaQueries.md} {
          max-width: 70px;
        }
      }
    }

    .logo-text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 24px;

        li {
          a {
            color: #fff;
            text-align: center;
            font-size: 16px;
            line-height: 1.5;
          }

          &.active {
            a {
              color: #03f1e3;
              font-weight: 700;
            }
          }
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .menu-actions-desktop {
        position: relative;
        display: flex;
        align-items: center;

        a {
          width: 100%;

          &:not(:last-child) {
            margin-right: 13px;
          }
        }

        button {
          &:not(:last-child) {
            margin-right: 13px;
          }
        }
      }

      .auth-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 24px;
        border-radius: 100px;
        background: #03f1e3;
        box-shadow: 0px 2px 24px 0px rgba(3, 241, 227, 0.12);

        > div {
          font-size: 16px;
          line-height: 1;
          letter-spacing: 0.4px;
          text-transform: capitalize;
        }
      }

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
`

const UserBox = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-left: 32px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        stroke: #00a0ff;
      }
    }
  }
`

interface Props {
  menuItems: MenuEntry[]
  hasSider?: boolean
}

const mobileWith = 767

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account } = useWeb3React()
  const contractMethod = useOpxContract()

  const userData = useUserData()
  const myWallet = useGetUsdtWallet()
  const [isWhiteList] = useGetDataByAddress(contractMethod, 'whiteList', account)

  const [showSlider, setShowSlider] = useState(false)
  const [web3DomainName, setWeb3DomainName] = useState<string>('')

  useMemo(async () => {
    if (account && setGetNameSevice) {
      const nameSevice = await setGetNameSevice(account)

      setWeb3DomainName(nameSevice)
    }
  }, [account])

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  const handleSignOut = useCallback(() => {
    dispatch(updateUserInfo(undefined))
    const isDelete = deleteAuthToken()
    if (isDelete) {
      router.push('/sign-in')
    }
  }, [dispatch, router])

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })
  return (
    <>
      <WrapperHeader background={!isScroll ? 'transparent' : '#000'}>
        <div className="content">
          {!hasSider ? (
            <>
              <div className="menu-left">
                <Link href="/">
                  <img src="/images/logo.png" alt="" />
                </Link>
              </div>
            </>
          ) : (
            <div />
          )}

          {!isMobile && (
            <div className="logo-text">
              <ul>
                {menuItems?.map((item) => {
                  if (item.label === 'Config' && !isWhiteList) return <></>

                  return (
                    <li className={router?.asPath === item?.href ? 'active' : ''} key={item?.label}>
                      <Link href={['Config'].includes(item.label) ? `${item?.href}/${account}` : item?.href}>
                        {item?.label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}

          <div className="menu-right">
            {isMobile ? (
              <CollapseIcon active={showSlider} toggleMenu={toggleMenu} />
            ) : (
              <div className="menu-actions-desktop">
                {!userData ? (
                  <>
                    <BaseButton className="auth-btn sign-in" onClick={toggleWallet}>
                      {web3DomainName || formatCode(account, 5, 5) || t('Connect Wallet')}
                    </BaseButton>
                  </>
                ) : (
                  <UserBox>
                    <Dropdown
                      overlay={
                        <DropdownUserMenu
                          userData={userData}
                          toggleMenu={toggleMenu}
                          onSignOut={handleSignOut}
                          myWallet={myWallet}
                        />
                      }
                      placement="bottomLeft"
                      trigger={['click']}
                      open={showSlider}
                      onOpenChange={toggleMenu}
                    >
                      <Box as="img" src={userData?.avatar} alt="" />
                    </Dropdown>
                  </UserBox>
                )}
              </div>
            )}
          </div>
        </div>
      </WrapperHeader>
      {isMobile && (
        <MobileSlider
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
          userData={userData}
          onSignOut={handleSignOut}
          myWallet={myWallet}
          web3DomainName={web3DomainName}
          isWhiteList={isWhiteList}
          account={account}
        />
      )}
    </>
  )
}

export default Header
