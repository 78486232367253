import { BalanceType, WalletType } from 'config/types/Wallet'
import { useSelector } from 'react-redux'
import { AppState } from 'state'

export const useGetOpvWallet = (): any => {
  return useSelector<AppState, AppState['wallet']['opvWallet']>((state) => state.wallet.opvWallet)
}

export const useGetUsdtWallet = (): any => {
  return useSelector<AppState, AppState['wallet']['usdtWallet']>((state) => state.wallet.usdtWallet)
}

export const useGetWalletBalance = (): any => {
  return useSelector<AppState, AppState['wallet']['balances']>((state) => state.wallet.balances)
}

export const useGetWalletData = (): any => {
  return useSelector<AppState, AppState['wallet']['balances']>((state) => state.wallet.balances)
}
