/* eslint-disable camelcase */
import Web3 from 'web3'
import abi_token from 'config/abi/abi_token.json'
import getNodeUrl from './getRpcUrl'

const rpcUrl = getNodeUrl()

class Web3Token {
  address: string

  web3: any

  contract: any

  constructor(address, abi?: any) {
    this.address = address
    this.web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl))
    this.contract = new this.web3.eth.Contract(abi || abi_token, address)
  }

  async getInfo(funcName) {
    try {
      return await this.contract.methods[funcName]().call()
    } catch (error) {
      console.error('getInfo', error)
      return false
    }
  }

  async symbol() {
    const response = await this.contract.methods.symbol().call()
    return response
  }

  async decimals() {
    const response = await this.contract.methods.decimals().call()
    return response
  }

  async balanceOf(address) {
    const response = await this.contract.methods.balanceOf(address).call()
    return response
  }

  async getFullInfo() {
    return Promise.all([await this.symbol(), await this.decimals()])
      .then(([symbol, decimals]) => {
        return { address: this.address, symbol, decimals }
      })
      .catch((error) => {
        console.error('E0089', error)
        return null
      })
  }

  async totalSupply() {
    const decimals = await this.decimals()
    const response = (await this.contract.methods.totalSupply().call()) / 10 ** decimals
    return response
  }

  async getLastedBlockNumber() {
    try {
      return await this.web3.eth.getBlockNumber()
    } catch (error) {
      return false
    }
  }

  async getEventLogContract(event, fromBlock, toBlock) {
    try {
      const topic = this.contract.events[event]
      return await this.contract.getPastEvents(event, {
        fromBlock,
        toBlock,
        topic,
      })
    } catch (error) {
      return false
    }
  }
}

export default Web3Token
