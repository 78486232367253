import { useWeb3React } from '@web3-react/core'
import { Grid } from 'antd'
import { BASE_STAKING_TOKEN } from 'config/constants/endpoints'
import styled from 'styled-components'
import { getBscScanLink, shortenAddress } from 'utils'
import { Link } from 'widgets'
import { TelegramIcon, TwitterIcon } from '../../../packages/uikit/src/components/Svg'

const { useBreakpoint } = Grid

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 42px 0;
  border-top: 1px solid rgba(250, 255, 255, 0.1);
  background-color: #0d0d0d;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 60px 0;
  }
`

const Communicate = styled.div`
  padding: 24px 16px;
  border-radius: 32px;
  background: radial-gradient(
    340.54% 169.54% at 119.18% 126.53%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(6, 6, 6, 0.6) 48.58%,
    rgba(27, 27, 27, 0.6) 100%
  );

  ${({ theme }) => theme.mediaQueries.md} {
  }
`

const ButtonWrapper = styled.div`
  padding: 12px 16px;
  border-radius: 999px;
  background: rgba(255, 255, 255, 0.12);

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px 24px;
  }
`

const Footer = () => {
  const breakPoint = useBreakpoint()

  return (
    <FooterWrapper>
      <div className="w-full max-w-[1262px] px-4 mx-auto flex flex-col gap-6">
        <Communicate className="flex lg:flex-row flex-col md:items-start items-center justify-between gap-8 w-full max-w-[1210px] mx-auto">
          <div className="flex md:flex-row flex-col items-center md:gap-6 gap-4">
            <img src="/images/logo.png" alt="logo" className="w-full max-w-[70px] aspect-square" />

            <div className="w-full lg:max-w-[550px] flex flex-col gap-3">
              <p className="text-[20px] leading-[1.5] md:text-left text-center md:text-base text-sm">
                We&apos;re committed to creating value for our community.
              </p>
              <p className="md:text-left text-center md:text-base text-sm text-[#FFFFFF80] ">
                A percentage of every transaction is burned, reducing the total supply and increasing the scarcity of
                Optimus X over time.
              </p>
            </div>
          </div>

          <div className="flex items-center gap-6">
            <Link href="/" external className="md:p-3 p-2 rounded-[12px] border-[1px] border-solid border-[#FFFFFF33]">
              <TwitterIcon className="md:w-[50px] w-[36px]" />
            </Link>

            <Link href="/" external className="md:p-3 p-2 rounded-[12px] border-[1px] border-solid border-[#FFFFFF33]">
              <TelegramIcon className="md:w-[50px] w-[36px]" />
            </Link>
          </div>
        </Communicate>

        <div className="flex lg:flex-row flex-col lg:items-center justify-between gap-6">
          <div className="flex flex-col gap-4">
            <p className=" md:text-left text-center">Feedback and Inquiries</p>
            <div className="flex items-center md:justify-start justify-center gap-3">
              <ButtonWrapper className="md:text-base text-sm">Contact us</ButtonWrapper>
              <p className="md:text-base text-sm">contact@optimusx.xyz</p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <p className=" md:text-left text-center">Contracts</p>
            <ButtonWrapper className="flex items-center md:justify-start justify-center md:gap-6 gap-2">
              <p className="md:text-base text-sm">BSC</p>
              <Link
                href={getBscScanLink(BASE_STAKING_TOKEN, 'address', 56)}
                external
                className="text-[#FFFFFF80] hover:text-[#03f1e3] md:text-base text-xs"
              >
                {breakPoint?.md ? BASE_STAKING_TOKEN : shortenAddress(BASE_STAKING_TOKEN, 14)}
              </Link>
            </ButtonWrapper>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer
